// tslint:disable
/**
 * Reservation Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */
export enum CorporateContactType {
    Emergency = 'Emergency',
    Sales = 'Sales',
    AccountsPayable = 'AccountsPayable',
    Admin = 'Admin',
    APSupervisor = 'APSupervisor',
    CEO = 'CEO',
    CFO = 'CFO',
    Director = 'Director',
    Manager = 'Manager',
    DistrictManager = 'DistrictManager',
    HousingManager = 'HousingManager',
    OfficeManager = 'OfficeManager',
    RegionalOperationsManager = 'RegionalOperationsManager',
    President = 'President',
    Other = 'Other'
}



