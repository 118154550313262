// tslint:disable
/**
 * Reservation Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';

import { CorporateContactListResponse } from '../models';
import { CorporateContactResponse } from '../models';
import { CreateCorporateContactRequest } from '../models';
import { UpdateCorporateContactRequest } from '../models';
/**
 * CorporateContactApi - axios parameter creator
 * @export
 */
export const CorporateContactApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} contactId 
         * @param {string} corporateAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corporatecontactCorporateAccountIdContactIdDelete(contactId: string, corporateAccountId: string, options: any = {}): RequestArgs {
            // verify required parameter 'contactId' is not null or undefined
            if (contactId === null || contactId === undefined) {
                throw new RequiredError('contactId','Required parameter contactId was null or undefined when calling corporatecontactCorporateAccountIdContactIdDelete.');
            }
            // verify required parameter 'corporateAccountId' is not null or undefined
            if (corporateAccountId === null || corporateAccountId === undefined) {
                throw new RequiredError('corporateAccountId','Required parameter corporateAccountId was null or undefined when calling corporatecontactCorporateAccountIdContactIdDelete.');
            }
            const localVarPath = `/corporatecontact/{corporateAccountId}/{contactId}`
                .replace(`{${"contactId"}}`, encodeURIComponent(String(contactId)))
                .replace(`{${"corporateAccountId"}}`, encodeURIComponent(String(corporateAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} corporateAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corporatecontactCorporateAccountIdGet(corporateAccountId: string, options: any = {}): RequestArgs {
            // verify required parameter 'corporateAccountId' is not null or undefined
            if (corporateAccountId === null || corporateAccountId === undefined) {
                throw new RequiredError('corporateAccountId','Required parameter corporateAccountId was null or undefined when calling corporatecontactCorporateAccountIdGet.');
            }
            const localVarPath = `/corporatecontact/{corporateAccountId}`
                .replace(`{${"corporateAccountId"}}`, encodeURIComponent(String(corporateAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} corporateAccountId 
         * @param {UpdateCorporateContactRequest} [updateCorporateContactRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corporatecontactCorporateAccountIdPost(corporateAccountId: string, updateCorporateContactRequest?: UpdateCorporateContactRequest, options: any = {}): RequestArgs {
            // verify required parameter 'corporateAccountId' is not null or undefined
            if (corporateAccountId === null || corporateAccountId === undefined) {
                throw new RequiredError('corporateAccountId','Required parameter corporateAccountId was null or undefined when calling corporatecontactCorporateAccountIdPost.');
            }
            const localVarPath = `/corporatecontact/{corporateAccountId}`
                .replace(`{${"corporateAccountId"}}`, encodeURIComponent(String(corporateAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof updateCorporateContactRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateCorporateContactRequest !== undefined ? updateCorporateContactRequest : {}) : (updateCorporateContactRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} corporateAccountId 
         * @param {CreateCorporateContactRequest} [createCorporateContactRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corporatecontactCorporateAccountIdPut(corporateAccountId: string, createCorporateContactRequest?: CreateCorporateContactRequest, options: any = {}): RequestArgs {
            // verify required parameter 'corporateAccountId' is not null or undefined
            if (corporateAccountId === null || corporateAccountId === undefined) {
                throw new RequiredError('corporateAccountId','Required parameter corporateAccountId was null or undefined when calling corporatecontactCorporateAccountIdPut.');
            }
            const localVarPath = `/corporatecontact/{corporateAccountId}`
                .replace(`{${"corporateAccountId"}}`, encodeURIComponent(String(corporateAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof createCorporateContactRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createCorporateContactRequest !== undefined ? createCorporateContactRequest : {}) : (createCorporateContactRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CorporateContactApi - functional programming interface
 * @export
 */
export const CorporateContactApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} contactId 
         * @param {string} corporateAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corporatecontactCorporateAccountIdContactIdDelete(contactId: string, corporateAccountId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = CorporateContactApiAxiosParamCreator(configuration).corporatecontactCorporateAccountIdContactIdDelete(contactId, corporateAccountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} corporateAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corporatecontactCorporateAccountIdGet(corporateAccountId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CorporateContactListResponse> {
            const localVarAxiosArgs = CorporateContactApiAxiosParamCreator(configuration).corporatecontactCorporateAccountIdGet(corporateAccountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} corporateAccountId 
         * @param {UpdateCorporateContactRequest} [updateCorporateContactRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corporatecontactCorporateAccountIdPost(corporateAccountId: string, updateCorporateContactRequest?: UpdateCorporateContactRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CorporateContactResponse> {
            const localVarAxiosArgs = CorporateContactApiAxiosParamCreator(configuration).corporatecontactCorporateAccountIdPost(corporateAccountId, updateCorporateContactRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} corporateAccountId 
         * @param {CreateCorporateContactRequest} [createCorporateContactRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corporatecontactCorporateAccountIdPut(corporateAccountId: string, createCorporateContactRequest?: CreateCorporateContactRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CorporateContactResponse> {
            const localVarAxiosArgs = CorporateContactApiAxiosParamCreator(configuration).corporatecontactCorporateAccountIdPut(corporateAccountId, createCorporateContactRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CorporateContactApi - factory interface
 * @export
 */
export const CorporateContactApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} contactId 
         * @param {string} corporateAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corporatecontactCorporateAccountIdContactIdDelete(contactId: string, corporateAccountId: string, options?: any): AxiosPromise<void> {
            return CorporateContactApiFp(configuration).corporatecontactCorporateAccountIdContactIdDelete(contactId, corporateAccountId, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} corporateAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corporatecontactCorporateAccountIdGet(corporateAccountId: string, options?: any): AxiosPromise<CorporateContactListResponse> {
            return CorporateContactApiFp(configuration).corporatecontactCorporateAccountIdGet(corporateAccountId, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} corporateAccountId 
         * @param {UpdateCorporateContactRequest} [updateCorporateContactRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corporatecontactCorporateAccountIdPost(corporateAccountId: string, updateCorporateContactRequest?: UpdateCorporateContactRequest, options?: any): AxiosPromise<CorporateContactResponse> {
            return CorporateContactApiFp(configuration).corporatecontactCorporateAccountIdPost(corporateAccountId, updateCorporateContactRequest, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} corporateAccountId 
         * @param {CreateCorporateContactRequest} [createCorporateContactRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corporatecontactCorporateAccountIdPut(corporateAccountId: string, createCorporateContactRequest?: CreateCorporateContactRequest, options?: any): AxiosPromise<CorporateContactResponse> {
            return CorporateContactApiFp(configuration).corporatecontactCorporateAccountIdPut(corporateAccountId, createCorporateContactRequest, options)(axios, basePath);
        },
    };
};

/**
 * CorporateContactApi - object-oriented interface
 * @export
 * @class CorporateContactApi
 * @extends {BaseAPI}
 */
export class CorporateContactApi extends BaseAPI {
    /**
     * 
     * @param {string} contactId 
     * @param {string} corporateAccountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorporateContactApi
     */
    public corporatecontactCorporateAccountIdContactIdDelete(contactId: string, corporateAccountId: string, options?: any) {
        return CorporateContactApiFp(this.configuration).corporatecontactCorporateAccountIdContactIdDelete(contactId, corporateAccountId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} corporateAccountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorporateContactApi
     */
    public corporatecontactCorporateAccountIdGet(corporateAccountId: string, options?: any) {
        return CorporateContactApiFp(this.configuration).corporatecontactCorporateAccountIdGet(corporateAccountId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} corporateAccountId 
     * @param {UpdateCorporateContactRequest} [updateCorporateContactRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorporateContactApi
     */
    public corporatecontactCorporateAccountIdPost(corporateAccountId: string, updateCorporateContactRequest?: UpdateCorporateContactRequest, options?: any) {
        return CorporateContactApiFp(this.configuration).corporatecontactCorporateAccountIdPost(corporateAccountId, updateCorporateContactRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} corporateAccountId 
     * @param {CreateCorporateContactRequest} [createCorporateContactRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorporateContactApi
     */
    public corporatecontactCorporateAccountIdPut(corporateAccountId: string, createCorporateContactRequest?: CreateCorporateContactRequest, options?: any) {
        return CorporateContactApiFp(this.configuration).corporatecontactCorporateAccountIdPut(corporateAccountId, createCorporateContactRequest, options)(this.axios, this.basePath);
    }

}
